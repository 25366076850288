<template>
	<div class="my-company">
		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight" :subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy" :offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity" :overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate" :titleColor="Head.TitleColor" />

		<v-container class="mt-16">

				<v-row>
					<v-col cols="12" lg="9">
						
						<v-card>
							<v-card-title>
								Modifier mon entreprise
							</v-card-title>
							<v-card-text>

								<v-form ref="form">
									<div class="mb-5 mt-2">
										<v-card class="mb-5">
											<v-card-text>
												<span class="headline mt-10">Rechercher par SIRET</span><br />
												<v-icon dense color="primary">mdi-alert-circle-outline</v-icon>
												<span class="caption ml-1">Vous pouvez pré-remplir les champs du formulaire si l'entreprise concernée est repertoriée dans le répertoire SIREN.</span>

												<v-row class="mt-0">
													<v-col cols="12" sm="6">
														<v-text-field label="SIRET" type="number" hint="SIRET du contact" v-model="compInfo.siret"></v-text-field>
													</v-col>
													<v-col>
														<v-btn class="ma-2 mt-4" @click="addCompanyBySIRET" outlined color="primary">Rechercher</v-btn>
													</v-col>
												</v-row>
											</v-card-text>
										</v-card>

										<v-row>
											<v-col cols="12" sm="6">
												<span class="headline mt-10">Profil</span>

												<v-text-field label="Nom" hint="Nom du contact" :rules="this.$fieldsRules.required('nom')" v-model="compInfo.name"></v-text-field>

												<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
													<template v-slot:activator="{ on, attrs }">
														<v-text-field v-model="formatDate" label="Date de création" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
													</template>
													<v-date-picker first-day-of-week="1" v-model="modelBirthdate" @input="menu = false"></v-date-picker>
												</v-menu>
											</v-col>

											<v-col cols="12" sm="6">
												<span class="headline mt-5 mb-1">Contact</span>

												<v-text-field label="N° de Téléphone" hint="N° de téléphone principal de l'entreprise" v-model="compInfo.phone"></v-text-field>
												<v-text-field label="Adresse Mail" hint="Adresse mail principal de l'entreprise" v-model="compInfo.email"></v-text-field>

												<v-text-field label="Site Web" hint="Lien du Site Web de l'Entreprise" v-model="compInfo.website">
												</v-text-field>

											</v-col>
										</v-row>

										<span class="headline mt-5 mb-1">Adresse</span>
										<v-row>
											<v-col cols="12" class="pb-0">
												<v-text-field label="Adresse" hint="Adresse de l'entreprise" v-model="compInfo.address.street"></v-text-field>
											</v-col>

											<v-col cols="12" sm="6" class="pt-0">
												<v-text-field label="Code Postal" hint="Code postal de l'entreprise" v-model="compInfo.address.postalCode"></v-text-field>
												<v-text-field label="Région" hint="Région de l'entreprise" v-model="compInfo.address.region"></v-text-field>
											</v-col>

											<v-col cols="12" sm="6" class="pt-0">
												<v-text-field label="Ville" hint="Ville de l'entreprise" v-model="compInfo.address.city"></v-text-field>
												<v-text-field label="Pays" hint="Pays de l'entreprise" v-model="compInfo.address.country"></v-text-field>
											</v-col>
										</v-row>

										<span class="headline mt-5 mb-1">A Propos</span>
										<v-row>
											<v-col cols="12" sm="6">
												<v-textarea v-model="compInfo.bio" name="input-1-1" label="Description" hint="Description"></v-textarea>
											</v-col>

											<v-col cols="12" sm="6">
												<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="company" label="Logo de l'entreprise" @newdata="compInfo.avatar=$event"></ImageUpload>
												<v-img :src="$functions.getImgLink(compInfo.avatar)" contain></v-img>
											</v-col>
										</v-row>
									</div>

									<span class="headline mt-10">Informations Bancaire</span>
									<v-card class="pa-5 mb-5 mt-2">
										<v-row>
											<v-col cols="4">
												<v-switch v-model="compInfo.tva.status" label="Assujétti à la TVA"></v-switch>
											</v-col>

											<v-col cols="8">
												<v-text-field label="Numéro TVA" v-if="compInfo.tva.status" hint="Numéro de TVA intracommunautaire de l'entreprise" v-model="compInfo.tva.number"></v-text-field>
											</v-col>

											<v-col cols="12">
												<v-row>
													<v-col cols="12" sm="6">
														<v-autocomplete :items="this.$listUtils.bankCountries" v-model="compInfo.bank_info.country" label="Code Pays"></v-autocomplete>
													</v-col>
													<v-col cols="12" sm="6">
														<v-text-field label="Banque" hint="Nom de la banque de l'entreprise" v-model="compInfo.bank_info.bank"></v-text-field>
													</v-col>
												</v-row>
												<v-text-field label="IBAN / BIC" hint="IBAN/BIC de l'entreprise" v-model="compInfo.bank_info.iban"></v-text-field>
											</v-col>
										</v-row>
									</v-card>

								</v-form>

							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="success" text @click="submit()">Modifier</v-btn>
							</v-card-actions>
						</v-card>

					</v-col>
					<v-col cols="12" lg="3">

						<v-card class="mb-5">
							<v-card-title>
								Modules
							</v-card-title>
							<v-card-text>

								<v-list>
									<v-list-item-group v-model="activeListModule" color="primary">
										<v-list-item v-for="item in modules" :key="item.id" @click="activeModule = item, dialogs.showModule = true">
											<!--<v-list-item-icon>
												<v-icon v-text="item.icon"></v-icon>
											</v-list-item-icon>-->
											<v-list-item-content>
												<v-list-item-title v-text="item.name"></v-list-item-title>
											</v-list-item-content>

											<v-list-item-icon v-if="item.index > -1">
												<v-icon color="success">mdi-check</v-icon>
											</v-list-item-icon>

											<v-list-item-icon v-else>
												<v-icon color="error">mdi-close</v-icon>
											</v-list-item-icon>
										</v-list-item>
									</v-list-item-group>
								</v-list>

							</v-card-text>
						</v-card>

						<v-card class="mb-5">
							<v-card-title>
								Mes quotas
							</v-card-title>
							<v-card-text>

								<v-list disabled>
									<v-list-item-group color="primary">
										<v-list-item v-for="item in quotas" :key="item.id">
											<v-list-item-icon>
												<v-progress-circular :rotate="90" :value="item.progress.percentage" :color="item.progress.color"></v-progress-circular>
											</v-list-item-icon>

											<v-list-item-content>
												<v-list-item-title v-text="item.name"></v-list-item-title>
												<v-list-item-subtitle>
													{{ item.size }} / <span v-if="item.quota == -1">∞</span><span v-else>{{ item.quota }}</span>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list-item-group>
								</v-list>

							</v-card-text>
						</v-card>

						<v-btn block color="primary" class="mb-5">
							Contacter l'assistance
						</v-btn>

						<v-btn block color="error">
							Supprimer mon entreprise
						</v-btn>

					</v-col>
				</v-row>
				
		</v-container>

		<v-dialog v-model="dialogs.showModule" width="70vw"  @click:outside="activeListModule = ''">
			<v-card>
				<v-card-title class="headline">
					{{ activeModule.name }}
				</v-card-title>
				<v-card-text>
					{{ activeModule.description }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text>
						En savoir plus
					</v-btn>
					<v-btn color="primary" text @click="dialogs.showModule = false, activeListModule = ''">
						Fermer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	const axios = require('axios');

	import Head from '@/components/graphics/head'
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		head: {
			title: {
				inner: "Mon Entreprise"
			}
		},
		name: 'MonEntreprise',
		components: {
			Head,
			ImageUpload
		},
		data() {
			return {
				userInfo: this.$models.user,
				compInfo: this.$models.company,

				Head: {
					Title: 'Mon entreprise',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?auto=format&fit=crop&w=1950",
					bgUrlLazy: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?auto=format&fit=crop&w=1950",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				dialogs: {
					showModule: false
				},

				activeModule: this.$models.module,
				activeListModule: '',

				date: null,
				menu: false,

				modules: [],
				companyModules: [],

				quotas: [],

				modelBirthdate: ''
			};
		},
		computed: {
			formatDate() {
				return this.modelBirthdate ? this.$moment(this.modelBirthdate).format('DD/MM/YYYY') : ''
			}
		},
		methods: {
			submit() {
				if(!this.$refs.form.validate()) return;

				var entreprise = this.compInfo;

				entreprise.birthdate = new Date(this.modelBirthdate);

				this.$db.collection("companies").doc(this.userInfo.company).set(entreprise, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `L'entreprise a été modifiée !`
					});
				});
			},

			async addCompanyBySIRET(){
				var config = {
					method: 'get',
					url: 'https://api.insee.fr/entreprises/sirene/V3/siret/' + this.compInfo.siret,
					headers: {
						'Accept': 'application/json',
						'Authorization': 'Bearer f39df87b-c8a7-3176-8b7b-eac78bb8c190'
					},
					data: {
						'quantity': '1',
						'offset': '0'
					}
				};
				axios(config).then((response) => {
					var comp = response.data.etablissement;
					
					this.compInfo.birthdate = this.$moment(new Date(comp.uniteLegale.dateCreationUniteLegale)).format("YYYY-MM-DD");

					if(comp.uniteLegale.denominationUniteLegale){
						this.compInfo.name = comp.uniteLegale.denominationUniteLegale;
					}
					else {
						var sexe = comp.uniteLegale.sexeUniteLegale == "F" ? "Mme" : "M";
						this.compInfo.name = sexe + " " + comp.uniteLegale.prenom1UniteLegale + " " + comp.uniteLegale.nomUniteLegale;
					}

					var config2 = {
						method: "get",
						url: 'https://api-adresse.data.gouv.fr/search/?q=' + comp.adresseEtablissement.numeroVoieEtablissement + "+" + comp.adresseEtablissement.typeVoieEtablissement + "+" + comp.adresseEtablissement.libelleVoieEtablissement + "&postcode=" + comp.adresseEtablissement.codePostalEtablissement,
						headers: {
							'Accept': 'application/json',
						},
						data: {
							'quantity': '1',
							'offset': '0'
						}
					}

					axios(config2).then((response) => {
						var address = response.data.features[0].properties;

						this.compInfo.address.street = address.name;
						this.compInfo.address.postalCode = address.postcode;
						this.compInfo.address.city = address.city;
						this.compInfo.address.country = "France";

						var splitContext = address.context.split(", ");
						this.compInfo.address.region = splitContext[1];
					});
				}).catch(() => {
					this.$store.dispatch('setSnackbar', {
						text: `L'entreprise au numéro SIRET ${this.compInfo.siret} n'a pas été trouvée dans le répertoire SIREN.`,
						color: 'error'
					});
				});
			},
			
			getModules() {
				this.modules = [];
				this.quotas = [];
				
				this.$db.collection("companies").doc(this.userInfo.company).collection("modules").onSnapshot((res) => {
					this.companyModules = [];

					res.forEach((compModules) => {
						this.companyModules.push({
							...compModules.data(),
							id: compModules.id,
							quota: compModules.data().quota || -1
						});
					});
				});

				this.$db.collection("modules").get().then((res) => {
					res.forEach((module) => {
						this.modules.push({
							...module.data(),
							id: module.id,
							index: this.$functions.findWithAttr(this.companyModules, "id", module.id)
						});

						if(this.$functions.findWithAttr(this.companyModules, "id", module.id) > -1) {
							this.$db.collection("companies").doc(this.userInfo.company).collection(module.data().collection.toLowerCase()).get().then((res) => {
								var t = this.companyModules[this.$functions.findWithAttr(this.companyModules, "id", module.id)];

								if(t) var quota = this.companyModules[this.$functions.findWithAttr(this.companyModules, "id", module.id)].quota;

								var percentage = Math.floor(100 * res.size / quota);
								if(quota == -1) percentage = 100;

								var color = "success";

								if(percentage > 75 && quota != -1) color = "warning";
								if(percentage == 100 && quota != -1) color = "error";

								this.quotas.push({
									size: res.size,
									name: module.data().name,
									quota: quota,
									progress: {
										percentage: percentage,
										color: color
									}
								});
							});
						}
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.getModules();

				this.$db.collection("companies").doc(this.userInfo.company).onSnapshot(doc => {
					this.compInfo = {
						...this.$models.company,
						...doc.data()
					};

					if(doc.data().birthdate) this.modelBirthdate = this.$functions.getDateFormat(doc.data().birthdate, "YYYY-MM-DD");
					else this.modelBirthdate = '';

					this.Head.SubTitle = this.compInfo.name;
				});

				
			});
		}
	}
</script>
